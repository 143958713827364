.select2Multiple {
    position: relative;
    margin-top: 11px;
    margin-bottom: 20px;
    width: 100%;
    font-family: Arial, sans-serif;
  }
  .select2Multiple__option.selected {
    background-color: #d1e7dd;
    color: #0f5132;
  }
  .select2Multiple__control {
    display: flex;
    align-items: center;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .select2Multiple__value {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    flex: 1;
  }
  
  .select2Multiple__tag {
    background-color: #e2e2e2;
    border-radius: 4px;
    padding: 2px 5px;
    display: flex;
    align-items: center;
  }
  
  .select2Multiple__remove {
    margin-left: 5px;
    cursor: pointer;
  }
  
  .select2Multiple__arrow {
    margin-left: 10px;
  }
  
  .select2Multiple__menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    z-index: 1;
    max-height: 150px;
    overflow-y: auto;
  }
  
  .select2Multiple__option {
    padding: 8px;
    cursor: pointer;
  }
  
  .select2Multiple__option:hover {
    background-color: #f2f2f2;
  }
  