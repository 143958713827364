.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
button.btn.btn-danger.btn-sm.mt-2.bt-minimum {
  border-radius: 17px;
  padding: 6px !important;
  font-size: 9px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.modal-add-lines .modal-content {
  background: #ECF0F1;
}

.modal-add-lines {
  top: 19em;
}

.modal-add-lines .mw-650px {
  max-width: 528px !important;
}

.check-success .MuiSwitch-colorSecondary.Mui-checked {

  color: #27AE60;
}

.check-success .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {

  background-color: #27AE60;
}

.modal.show .modal-dialog {
  transform: perspective(10px) !important;
}