.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  
  .pagination-button {
    border-radius: 17px;
    padding: 8px 12px;
    margin: 0 4px;
    border: 1px solid #ccc;
    background: #fff;
    cursor: pointer;
  }
  
  .pagination-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .pagination-button.active {
    background: #007bff;
    color: #fff;
    border-color: #007bff;
  }